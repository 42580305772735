
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AtomNotification from '@/components/atoms/AtomNotification.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomDropdownAddressSearch, { OptionCategory } from '@/components/atoms/AtomDropdownAddressSearch.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';

import getImageURL from '@/services/assets/AssetsService';
import {
  getDynamicSvg,
  createMarkerBase,
  resizeMap,
  createMarker,
} from '@twogo/here-maps/src/lib/HereMapsService';
import { browserGeolocation, route, revGeoCode } from '@/services/geolocation/geolocationService';
import getGenericAddresses from '@/services/geolocation/genericAddressBuilder';

import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import RouteReqModel from '@twogo/geolocation/src/lib/models/routing/requests/RouteReqModel';
import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import TransportModeEnum from '@/enums/rides/TransportModeEnum';
import RoutingModeEnum from '@/enums/rides/RoutingModeEnum';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import locationFlag from '@/assets/images/icons/icon-pin-flag.svg';

import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import SourceEnum from '@/enums/geolocation/SourceEnum';
import { quicksearch } from '@/api/geolocation/quicksearch/quicksearchApi';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

import { hasFeature } from '@/services/feature/FeatureService';

export default Vue.extend({
  name: 'OrganismOutboundTrip',
  data() {
    return {
      routes: [] as RouteModel[],
      geoSuggestions: [] as GenericAddressModel[],
      SwitchEnum,
      RolesEnum,
      FeaturesEnum,
      hereMap: undefined as any,
      hereMapGroup: undefined as any,
      locationHintMarker: undefined as any,
      geoSearchString: '',
    };
  },
  components: {
    AtomText,
    AtomActionText,
    AtomDropdownAddressSearch,
    AtomNotification,
    AtomSvgIcon,
    MoleculeHereMap,
  },
  computed: {
    ...mapState('rideIntent', ['outboundTrip']),
    ...mapGetters('rideIntent', [
      'isEditMode',
      'isRecurrenceTrip',
      'isSequenceTrip',
      'isOutwardInThePast',
      'getSelectedRole',
      'getReturnTripStatus',
      'getStartCoordinates',
      'getEndCoordinates',
      'getStartPoint',
      'getEndPoint',
      'getWaypoints',
      'maxNrOfWaypointsReached',
      'getMeetingPoint',
    ]),
    ...mapGetters('user', ['getUserId', 'b2cFree', 'features']),
    ...mapGetters('poi', ['getPoi', 'getHomePoi']),
    isB2cFree(): boolean {
      return this.b2cFree;
    },
    categories(): OptionCategory[] {
      return [
        { value: SourceEnum.ALL, title: 'dictionary.all', selected: true, isVisible: true },
        { value: SourceEnum.FAVORITES, title: 'dictionary.favorites', selected: false, isVisible: true },
        { value: SourceEnum.HISTORY, title: 'dictionary.history', selected: false, isVisible: true },
        { value: SourceEnum.SITE, title: 'dictionary.places', selected: false, isVisible: !this.b2cFree },
      ];
    },
    getMarkerLocations(): BaseMarkerModel[] {
      const markers = [] as BaseMarkerModel[];
      if (this.getStartPoint.location !== null) {
        markers.push(
          createMarkerBase(
            locationPin,
            this.getStartPoint.location.position,
            this.getStartPoint.id,
          ),
        );
      }
      this.getWaypoints.forEach((stopOver, index: number) => {
        if (stopOver.location !== null) {
          markers.push(
            createMarkerBase(getDynamicSvg(index), stopOver.location.position, stopOver.id),
          );
        }
      });
      if (this.getEndPoint.location !== null) {
        markers.push(
          createMarkerBase(locationFlag, this.getEndPoint.location.position, this.getEndPoint.id),
        );
      }
      return markers;
    },
    getGeolocation(): GenericAddressModel[] {
      const { getPoi, geoSuggestions, geoSearchString } = this;
      return [
        ...(geoSearchString || geoSuggestions.length ? [] : getGenericAddresses(getPoi)),
        ...this.geoSuggestions,
      ];
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'addStartPointLocation',
      'addEndPointLocation',
      'addWaypoint',
      'updateWaypoint',
      'removeWaypoint',
      'switchOriginAndArrival',
      'validateOutboundTripRoute',
      'setMeetingPoint',
    ]),
    hasFeature,
    locationHover(coordinates) {
      if (this.hereMap) {
        if (!this.locationHintMarker) {
          this.locationHintMarker = createMarker({ coordinates, icon: locationPin as any });
          this.hereMap.addObject(this.locationHintMarker);
        }
        this.locationHintMarker.setGeometry(coordinates);
        this.hereMap.setCenter(coordinates);
        this.hereMap.setZoom(14);
      }
    },
    locationHoverEnd() {
      if (this.locationHintMarker) {
        this.hereMap.removeObject(this.locationHintMarker);

        this.locationHintMarker = undefined as any;
        resizeMap(this.hereMap, this.hereMapGroup);
      }
    },
    async localizeMe(id: string) {
      const { success, coordinates, message = '' } = await browserGeolocation();
      if (success && coordinates) {
        const { data } = await revGeoCode({ at: coordinates });

        this.applyAddressChangeOnMarkerDrag(getGenericAddresses(data), id);
      } else {
        toast(this.$bvToast, message, ToastVariants.DANGER);
      }
    },
    getImagePath(icon: string): string {
      return getImageURL(icon);
    },
    addStopover() {
      if (!this.maxNrOfWaypointsReached) {
        this.addWaypoint().then(() => {
          this.validateOutboundTripRoute();
          this.setRouteSections();
        });
      }
    },
    removeStopover(index: number) {
      this.removeWaypoint(index).then(() => {
        this.validateOutboundTripRoute();
        this.setRouteSections();
      });
    },
    updateStopover({ index, location }: { index: number; location: GenericAddressModel }) {
      this.updateWaypoint({ location, index }).then(this.applyRouteChanges);
    },
    applyAddressChangeOnMarkerDrag(results: GenericAddressModel[], id: string) {
      if (!results.length) return;
      const location = results[0];
      const {
        position: { lng, lat },
      } = location;

      if (
        this.getStartPoint.id === id &&
        (!this.getStartPoint.location ||
          (!lng !== this.getStartPoint.location.position.lng &&
            lat !== this.getStartPoint.location.position.lat))
      ) {
        this.addStartPoint(results[0]);
      }
      if (
        this.getEndPoint.id === id &&
        (!this.getEndPoint.location ||
          (lng !== this.getEndPoint.location.position.lng &&
            lat !== this.getEndPoint.location.position.lat))
      ) {
        this.addEndPoint(results[0]);
      }
      let indexOfWaypoint = 0;
      if (
        this.getWaypoints.some((waypoint, index: number) => {
          if (
            waypoint.id === id &&
            (!waypoint.location ||
              (lng !== waypoint.location.position.lng && lat !== waypoint.location.position.lat))
          ) {
            indexOfWaypoint = index;
            return true;
          }
          return false;
        })
      ) {
        this.updateStopover({ index: indexOfWaypoint, location: results[0] });
      }
    },
    async searchAddressByCoordinates(coordinates: CoordinatesModel, id: string) {
      const { data } = await revGeoCode({ at: coordinates });
      this.applyAddressChangeOnMarkerDrag(getGenericAddresses(data), id);
    },
    async getGeoSuggestions(location: string, loading) {
      const { getUserId: id } = this;
      if (location.length >= 3) {
        loading(true);
        const { data } = await quicksearch(location, id);
        this.geoSuggestions = data;
        this.geoSearchString = location;
        loading(false);
      } else {
        this.geoSuggestions = [];
        this.geoSearchString = '';
        loading(false);
      }
    },
    applyRouteChanges() {
      this.validateOutboundTripRoute();
      this.setRouteSections();
      this.locationHoverEnd();
      this.geoSuggestions = [];
      this.geoSearchString = '';
    },
    addStartPoint(location: GenericAddressModel) {
      this.addStartPointLocation(location).then(this.applyRouteChanges);
    },
    addEndPoint(location: GenericAddressModel) {
      this.addEndPointLocation(location).then(this.applyRouteChanges);
    },
    switchDirection() {
      this.switchOriginAndArrival().then(() => {
        this.validateOutboundTripRoute();
        this.setRouteSections();
      });
    },
    setRouteSections() {
      const markerList = this.getMarkerLocations;
      const nrOfMarkers = markerList.length;
      if (nrOfMarkers > 1) {
        const stopOverCoordinates: CoordinatesModel[] = [];
        markerList.forEach(({ coordinates }: BaseMarkerModel, index: number) => {
          if (index !== 0 && index !== nrOfMarkers - 1) {
            stopOverCoordinates.push(coordinates);
          }
        });

        const queryModel = {
          transportMode: TransportModeEnum.CAR,
          origin: markerList[0].coordinates,
          destination: markerList[nrOfMarkers - 1].coordinates,
          routingMode: RoutingModeEnum.FAST,
          via: stopOverCoordinates,
        } as RouteReqModel;

        route(queryModel).then(({ data: routes }) => {
          if (routes) {
            this.routes = routes;
          }
        });
      }
    },
  },
  mounted() {
    this.setRouteSections();
  },
});
